<template>
  <div class="signature">
    <div class="top">
      <p>请您手写签名</p>
      <p>请在空白区域签写您的姓名</p>
    </div>
    <div class="content">
      <div class="sign" v-if="signShow"><p>签名区域</p></div>
      <canvas ref="canvasDom" class="canvas"></canvas>
    </div>
    <div class="footer">
      <div class="step" @click="goBack">取消</div>
      <div class="confirm">
        <p class="clear-btn" @click="clearHandle">清空</p>
        <van-button :class="btnClass" :disabled="disabled" @click="handleNext">确认</van-button>
      </div>
    </div>
  </div>
</template>

<script type="ts">
import { computed, defineComponent, onMounted, onUnmounted, ref } from 'vue'
import { useRouter } from 'vue-router'
import SignaturePad from 'signature_pad'
import { dataURLtoFile } from '@/utils/file'
import uploadApi from '@/axios/upload'
import { ImageType, BusinessType, Source } from '@/enum'
import useOpenInfo from '@/hooks/openInfo'
import beforeUpload from '@/utils/before-upload'
import { Message } from '@/components/Message'

export default defineComponent({
  setup () {
    const { openInfo, openType } = useOpenInfo()
    const router = useRouter()
    const canvasDom = ref()
    const signShow = ref(true)
    const signaturePad = ref()
    const resizeCanvas = () => {
      const ratio = Math.max(window.devicePixelRatio || 1, 1)
      canvasDom.value.width = canvasDom.value.offsetWidth * ratio
      canvasDom.value.height = canvasDom.value.offsetHeight * ratio
      canvasDom.value.getContext('2d').scale(ratio, ratio)
      signaturePad.value.clear()
      signShow.value = true
    }

    const initSignaturePad = () => {
      signaturePad.value = new SignaturePad(canvasDom.value, {
        // backgroundColor: '#5A6380',
        backgroundColor: '#fff',
        // penColor: '#000',
        onBegin: () => {
          signShow.value = false
        }
      })
      resizeCanvas()
    }

    // 禁用按钮
    const disabled = computed(() => signShow.value)

    // 禁用按钮样式
    const btnClass = computed(() => {
      if (disabled.value) {
        return 'disabled'
      }
      return 'comfire-btn'
    })

    // 处理转向 并压缩图片
    const handleCompress = (img, orientation) => {
      let degree = 0
      let drawWidth = img.width
      let drawHeight = img.height

      const width = drawWidth
      const height = drawHeight

      const canvas = document.createElement('canvas')
      canvas.width = width
      canvas.height = height
      const ctx = canvas.getContext('2d')
      switch (orientation) {
      // iphone竖屏签名，此时home键在下方(正常拿手机的方向)
        case 0:
          canvas.width = height
          canvas.height = width
          degree = 270
          drawWidth = -width
          drawHeight = height
          break
        default:
          break
      }
      ctx.rotate((degree * Math.PI) / 180)

      ctx.drawImage(img, 0, 0, drawWidth, drawHeight)

      const imgBase = canvas.toDataURL('image/jpeg', 1)
      return imgBase
    }

    const handleRotateSign = () => new Promise((resolve) => {
      const imgBase64 = signaturePad.value.toDataURL('image/jpeg')
      const img = new Image()
      img.src = imgBase64
      img.onload = () => {
        resolve(handleCompress(img, window.orientation))
      }
    })

    // 下一步
    const handleNext = async () => {
      const imgBase64 = await handleRotateSign()
      const file = dataURLtoFile(imgBase64, 'sign.png')
      beforeUpload(file).then((res) => {
        const formatFile = new File([res], res.name, { type: res.type })
        const params = {
          file: formatFile,
          imageType: ImageType.ACC_IMAGE_TYPE_301,
          businessType: BusinessType.ACC_FILE_BUSINESS_TYPE_1,
          kindSource: openType.value,
          idKindType: openInfo.value.idKind,
          source: Source.H5
        }
        uploadApi.uploadFile(params).then((res) => {
          const { openAccountUploadFile } = res
          if (openAccountUploadFile) {
            router.go(-1)
            console.log('openAccountUploadFile', openAccountUploadFile)
          } else {
            console.log('上传失败')
            Message({
              direction: 'vertical',
              text: openAccountUploadFile?.reason?.desc || '上传失败，请重试'
            })
          }
        })
      })
    }

    const clearHandle = () => {
      signaturePad.value.clear()
      signShow.value = true
    }
    const goBack = () => {
      router.go(-1)
    }
    onMounted(() => {
      initSignaturePad()
      const telEl = document.querySelector('.float_button')
      telEl.style.display = 'none'
    })

    onUnmounted(() => {
      const telEl = document.querySelector('.float_button')
      telEl.style.display = ''
    })

    return {
      canvasDom,
      signaturePad,
      initSignaturePad,
      // resizeCanvas,
      clearHandle,
      handleNext,
      goBack,
      signShow,
      disabled,
      btnClass
    }
  }
})
</script>

<style scoped lang="scss">
.signature {
  padding: 41px 51px 41px 71px;
}
.top {
  position: fixed;
  top: 41px;
  left: 0;
  transform: rotate(90deg);
  transform-origin: 0 0;
  width: calc(100vh - 82px);
  margin-left: calc(100vw - 16px);
  display: flex;
  justify-content: space-between;
  & > p:nth-child(1) {
    font-size: 18px;
    font-family: Source Han Sans CN;
    font-weight: 400;
    line-height: 26px;
    color: #FFFFFF;
    opacity: 1;
  }
  & > p:nth-child(2) {
    font-size: 14px;
    font-family: Source Han Sans CN;
    font-weight: 400;
    line-height: 20px;
    color: #BFC7DE;
    opacity: 1;
  }
}

.content {
  position: relative;
  width: 100%;
  height: calc(100vh - 82px);
  background: #212639;
  opacity: 1;
  border-radius: 4px;
  .canvas {
    width: 100%;
    height: 100%;
  }
  .sign {
    @include pos-center();
    p {
      transform: rotate(90deg);
      // width: 192px;
      width: 220px;
      // height: 70px;
      font-size: 48px;
      font-family: Source Han Sans CN;
      font-weight: 400;
      line-height: 70px;
      color: #2A3148;
      opacity: .2;
    }
  }
}

.footer {
  position: fixed;
  top: 41px;
  left: 0;
  transform: rotate(90deg);
  transform-origin: 0 0;
  width: calc(100vh - 82px);
  margin-left: 55px;
  display: flex;
  justify-content: space-between;
  .step {
    width: 120px;
    height: 35px;
    line-height: 35px;
    text-align: center;
    background: #0F1423;
    border: 1px solid #959DB7;
    opacity: 1;
    border-radius: 8px 0px 8px 0px;
  }
  .confirm {
    display: flex;
    text-align: center;
    line-height: 35px;
    .clear-btn {
      width: 120px;
      height: 35px;
      background: #0F1423;
      border: 1px solid #959DB7;
      opacity: 1;
      border-radius: 8px 0px 8px 0px;
      margin-right: 15px;
    }
    .comfire-btn {
      width: 205px;
      height: 35px;
      background: #FFB400;
      opacity: 1;
      border-radius: 8px 0px 8px 0px;
    }
  }
}

.disabled {
  background-color: #363d56;
  opacity: 0.9;
  color: #161617;
  width: 205px;
  height: 35px;
  opacity: 1;
  border-radius: 8px 0px 8px 0px;
}
</style>
